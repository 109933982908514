import { useEffect, useState, useRef } from "react";
// import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { landingPage_projects, landingPage_projectDetails } from '../store/action'

export default function LandingPage(props) {
  const dispatch = useDispatch();

  // console.log("propsssss", props.reff);

  // const [apiData, setApiData] = useState(null);
  // const [projectFields, setProjectFields] = useState(null);

  const apiData = useSelector((state) => state.AuthReducer.apiData_landingPage);
  const projectFields = useSelector((state) => state.AuthReducer.projectFields_landingPage);

  // console.log("apiData", apiData);
  // console.log("projectFields", projectFields);


  const projectRef = useRef(null)
  const [searchParams] = useSearchParams();

  const scroll = searchParams.get("scroll")

  useEffect(() => {
    if (searchParams.get("scroll"))
      projectRef.current.scrollIntoView({ behavior: "smooth" });
  }, [scroll]);


  useEffect(() => {
    if (!apiData) {
      dispatch(landingPage_projects())
    }
    if (!projectFields) {
      dispatch(landingPage_projectDetails())
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Registry - Trusted Carbon</title>
        <meta
          name="description"
          content="The Hemp Carbon Standard produces high integrity Carbon Removal Credits derived from a science based quantification methodology."
        />
        <meta
          name="keywords"
          content="Carbon Removal Credits, Buy Carbon Removal credits, Carbon Removal Credits USA, Buy Carbon Removal credits USA, Carbon Sequestration Credits, Buy Carbon Sequestration credits, 
          Carbon Removal Credits Europe, Buy Carbon Removal Credits, Carbon Removal Credits Canada, Buy Carbon Removal Credits Canada, 
          Carbon Removal Credits California, Carbon Removal Credits For Sale, Carbon Removal Credits Ukraine, Buy Carbon Removal Credits Ukraine."
        />
      </Helmet>


      <div className='w-full'>
        {/* section 1 */}
        {/*  */}
        <div className='h-[600px] md:h-[calc(100vh-80px)] w-full flex flex-col-reverse md:flex-row'>
          <div className='mt-[25px] md:mt-0 w-full md:absolute  h-[300px] md:h-[calc(100vh-80px)]'
            style={{
              backgroundImage: "url(/Image2.webp)",
              height: 'calc("100vh - 80px")',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          >
          </div>
          <div className='h-[250px] md:h-[calc(100vh-80px)] w-full md:w-1/2 md:ml-[50%] z-10 flex items-center justify-center bg-white'>
            <p className='w-[94%] md:w-[60%] text-[32px] md:text-[48px] font-bold text-center md:text-left'>
              A UNIQUE REGISTRY FOR PRECISION QUANTIFICATION METHODOLOGIES
            </p>
          </div>
        </div>

        {/* section 2 */}

        <div className='flex flex-col md:flex-row py-10 md:mt-10'>

          <div className='w-full md:w-1/2 flex flex-col'>
            <p className='px-2 md:px-8 text-[28px] md:text-[32px] text-center md:text-left'>
              Enabling Carbon Sequestration from Industrial Hemp Farming and Sustainable Products
            </p>
            <div className=' flex justify-center w-full md:w-[80%] items-center flex-1 px-8 mt-[40px]  md:mt-[20px]'>
              <img src='/Image14.webp' className='' />
            </div>
          </div>

          <div className='w-full md:w-1/2 mt-10 md:mt-0 space-y-7 justify-between'>
            <p className='text-[17px] px-4 md:px-20'>On the Trusted Carbon registry, you can view C02 Removal Certificates, or CRCs, that have been retired and the projects that issued them. CRCs are retired when a beneficiary makes a net zero or carbon neutrality claim that is supported by the CRCs' carbon sequestration properties.</p>
            <p className='text-[17px] px-4 md:px-20'>Hemp Carbon Standard (“HCS”) developed the first carbon sequestration methodology for industrial hemp that can be captured through planting industrial hemp and processing the crop into high impact sustainable products with durability.</p>
            <p className='text-[17px] px-4 md:px-20'> Our methodology is science-based and uses a life cycle analysis (LCA) for reporting emissions and stored carbon. or C02-equivalents. Removed carbon is the sequestered carbon minus the emissions that are calculated as part of the product or processes' LCA. The verification of sequestered carbon is calculated for each project on the registry by an independent auditor to ensure that C02 statements comply with the HCS Standard's science-based methodology.</p>
          </div>

        </div>


        {/* section 3 */}


        <div ref={projectRef}
        >
          {!apiData || !projectFields ? (
            <div className="h-[400px] flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 animate-spin"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
          ) : (
            <div className="bg-[#f0f0f0] py-8 md:py-14 mt-8" id="listings"
            // ref={props?.reff?.projectRef}
            >
              <div className="grid grid-cols-1 md:grid-cols-3 w-[90%] ml-[5%] gap-x-8 gap-y-14">
                {apiData?.map((d) => (
                  <div
                    key={d.id}
                    className="flex flex-col  rounded-md border-8 border-solid  border-[#ffffff]  bg-[#fafafa] pb-8"
                  >
                    <div className="h-[240pex] rounded-md">
                      <img
                        alt="Carbon Removal Credits, Buy Carbon Removal Credits"
                        src={d.picture_of_farm}
                        className="max-w-[100%] max-h-[100%] rounded-md"
                      />
                    </div>
                    <p className="font-bold mt-2 px-2">{d.farm}</p>

                    <div className="mt-4">
                      <p className="text-[14px] px-2">Category - {projectFields?.category} </p>
                      <p className="text-[14px] mt-2 px-2">Standard -  {projectFields?.standard} </p>
                      <p className="text-[14px] mt-2 px-2">Project Type - {projectFields?.project_type} </p>
                      <p className="text-[14px] mt-2 px-2">Validator - {projectFields?.validator}</p>
                      <p className="text-[14px] mt-2 px-2">Locations - {d?.country}</p>

                    </div>


                    <Link to={`/project-detail?id=${d.id}`} className="w-[220px]">
                      <p
                        className="bg-green-600 hover:bg-green-700 mt-6 ml-2 text-white 
                      py-2  rounded-md text-center"
                      >
                        More
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>

  )
}
