import React from 'react'

export default function Footer() {
    return (
        <div className="h-screen w-full mt- flex flex-col justify-center relative 
        "
            style={{
                backgroundImage: "url(/footer.webp)",
                height: '100vh',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div className='absolute bg-[#000] h-screen w-full opacity-10'></div>


            <div className='h-[50vh] flex flex-col justify-around pl-10 z-10'>
                <div className=''>
                    <p className='text-[24px]'>Office</p>
                </div>

                <div>
                    <p>Great Portland Street</p>
                    <p>London W1W7LT</p>
                </div>
                <div>
                    <p>www.trustedcarbon.org</p>
                    <p>Email: info@trustedcarbon.org</p>
                </div>
                <div className='flex flex-col'>
                    <p className='text-[20px]'>Socials</p>
                    <div className='flex mt-2' >
                        {/* <img src='/twitter.png' className='w-[30px] h-[30px]' />
                        <img src='/facebook.png' className='w-[28px] h-[28px] ml-1' />
                        <img src='/instagram.png' className='w-[30px] h-[30px] ml-1' /> */}
                        <a href="https://www.linkedin.com/company/trusted-carbon/mycompany/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                            <img src='/linkedin.png' className='w-[30px] h-[30px] ml-1' />
                        </a>
                    </div>
                </div>
            </div>

            <div className='absolute bottom-2 right-2 w-[100px] h-[60px]'>
                <img src='/logo.webp' className='w-[100px] h-[60px]' />
            </div>
        </div>
    )
}
