// import { combineReducers } from "redux";
import * as types from "./actionTypes";

const initialAuthState = {
  apiData_landingPage: null,
  projectFields_landingPage: null,
};

const AuthReducer = (state = initialAuthState, { type, payload }) => {
  switch (type) {
    case types.PROJECTS:
      return {
        ...state,
        apiData_landingPage: payload.apiData_landingPage,
      };
    case types.PROJECT_FIELDS:
      return {
        ...state,
        projectFields_landingPage: payload.projectFields_landingPage,

      };

    default:
      return state;
  }
};

export default AuthReducer;
