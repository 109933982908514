import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landingPage";
import Retirements from "./pages/retirements";
import ProjectDetail from "./pages/projectDetail";


export default function MyRoutes() {

    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/retirements" element={<Retirements />} />
            <Route path="/project-detail" element={<ProjectDetail />} />
        </Routes>
    );
}
