import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function Retirements() {
    const [retirements, setRetirements] = useState(null)

    useEffect(() => {
        axios.get("https://office.hempcarbonstandard.org/projectdetail/retirement-for-web")
            .then((d) => {
                // console.log(d.data);
                setRetirements(d.data)
            })
            .catch(() => {
                console.log("error fetching retirements");
            })
    }, [])

    return (
        <>
            <Helmet>
                <title>Registry - Trusted Carbon</title>
                <meta
                    name="description"
                    content="The Hemp Carbon Standard produces high integrity Carbon Removal Credits derived from a science based quantification methodology."
                />
                <meta
                    name="keywords"
                    content="Carbon Removal Credits, Buy Carbon Removal credits, Carbon Removal Credits USA, Buy Carbon Removal credits USA, Carbon Sequestration Credits, Buy Carbon Sequestration credits, 
                      Carbon Removal Credits Europe, Buy Carbon Removal Credits, Carbon Removal Credits Canada, Buy Carbon Removal Credits Canada, 
                    Carbon Removal Credits California, Carbon Removal Credits For Sale, Carbon Removal Credits Ukraine, Buy Carbon Removal Credits Ukraine."
                />
            </Helmet>

            <div className='w-full'>

                <div className='space-y-6 mt-4 w-[96%] ml-[2%] py-6'>
                    <p className='text-[22px] font-bold'>Trusted Carbon: Pioneering Carbon Removal</p>

                    <p>The Trusted Carbon platform showcases CO2 Removal Certificates (CRCs) that have been marked as retired, alongside the projects responsible for their issuance. Credits receive the 'retired' status once a beneficiary establishes a net zero or carbon neutrality declaration upheld by the standards of Trusted Carbon's sequestration features.</p>
                    <p>Carbon sequestration denotes the process of trapping CO2 from the atmosphere and confining it within a long-lasting carbon reservoir. Unlike conventional carbon offsets, which primarily target emission reductions, CRCs zero in on top-tier carbon extractions, commonly referred to as CDR – Carbon Dioxide Removal.</p>
                    <p>Our processes are firmly rooted in science, employing meticulous quantification techniques for greenhouse gas (GHG) tabulation. We also conduct lifecycle analyses (LCA) to detail emissions and retained carbon, or CO2-equivalents. The net removed carbon is the difference between sequestered carbon and emissions derived from a product or process's LCA. To ensure authenticity, an independent auditor verifies sequestered carbon, thoroughly examining the evidence that bolsters CO2 declarations.</p>

                </div>


                <div className='overflow-auto pt-4 pb-8 w-[96%] ml-[2%]'>
                    <table className='w-[2000px]'>
                        <thead className='h-[60px]'>
                            <tr className='w-full bg-[#f5f5f5]'>
                                <th className=''><p> DATE</p></th>
                                <th className=''><p> RETIRED CREDITS</p></th>
                                <th><p>CERTIFICATE NUMBERS (FROM - TO)</p></th>
                                <th><p>CREDIT TYPE</p></th>
                                <th><p>METHODOLOGY</p></th>
                                <th><p>BENEFICIARY</p></th>
                                <th ><p>RETIREMENT PURPOSE</p></th>
                                <th ><p>COUNTRY OF CONSUMPTION</p></th>
                                <th><p>PROJECT COUNTRY</p></th>
                                <th><p>PROJECT NAME</p></th>
                            </tr>
                        </thead>
                        <tbody className='mt-20 border-0 w-full'>

                            {retirements?.map(d => (
                                <tr className='h-[60px] text-center'>
                                    <td className=''><p>{d.retirement_date}</p></td>
                                    <td className=''><p>{d.total_issued_in_this_vintage}</p></td>
                                    <td className=''><p>{<>{d.start_code} - <br /> {d.end_code}</>}</p></td>
                                    <td className=''><p>{d.credit_type}</p></td>
                                    <td className=''><p>{d.methodology}</p></td>
                                    <td className=''><p>{d.beneficiary}</p></td>
                                    <td className=''><p>{d.retirement_purpose}</p></td>
                                    <td className=''><p>{d.country_of_consumption}</p></td>
                                    <td className=''><p>{d.project_country}</p></td>

                                    <td className='text-[#1890ff]'>
                                        <Link to={`/project-detail?id=${d.project_id}`}>
                                            <p> {d.project_name}</p>
                                        </Link>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>
        </>
    )
}
