import './App.css';
import Layout from './layout/layout';
import { BrowserRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';

import { Provider } from "react-redux";
import { useStore } from "./store/store.js";

const ScrollToTop = () => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
};



function App(props) {
  const store = useStore(props.initialReduxState);
  return (
    <Provider store={useStore(store)}>
      <BrowserRouter>
        <ScrollToTop />
        <Layout />
      </BrowserRouter>
    </Provider>

  );
}

export default App;
