import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
    return (

        <div className='bg-[#505e84] w-full h-[80px] flex justify-center items-center'>
            <div className='absolute w-full h-[80px] flex pl-10 z-10'>
                <Link to="/">
                    <img src='/logo.webp' className='h-[60px] z-50' />
                </Link>
            </div>
            <ul className='flex text-white w-1/2 ml-[50%] md:w-[40%] md:ml-0 justify-center font-semibold text-[16px] md:text-[20px] z-50'>
                <li className='mr-4 cursor-pointer '
                >
                    <Link to={`/?scroll=${true}`}>
                        <p> Projects</p>
                    </Link>
                </li>
                <li className='ml-4 cursor-pointer'>
                    <Link to='/retirements'>
                        <p> Retirements</p>
                    </Link>
                </li>
            </ul>


        </div >


    )
}
