import { useState, useEffect } from 'react'
import {
    useSearchParams
    // , Link
} from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet';
// import { useNavigate } from 'react-router-dom'


export default function ProjectDetail() {
    const [searchParams] = useSearchParams();
    const [projectId, setProjectId] = useState(null)
    const [retirementBaseOnProject, setRetirementBaseOnProject] = useState(null)
    const [docsBaseOnProject, setDocsBaseOnProject] = useState([])
    const [apiData, setApiData] = useState(null);
    const [projectFields, setProjectFields] = useState(null);
    const [yearList, setYearList] = useState(null)

    // 
    useEffect(() => {
        const project_Id = searchParams.get("id");
        // console.log("sss", projectId);
        setProjectId(project_Id)
    }, [])

    // useEffect(() => {
    // console.log("year", docsBaseOnProject);
    // })

    useEffect(() => {
        if (projectId) {
            axios.get(` https://office.hempcarbonstandard.org/projectdetail/retirement-detail?id=${projectId}`)
                .then(d => {
                    setRetirementBaseOnProject(d.data)
                    // console.log("ret", d.data);

                    let years = d.data.map(d => d.retirement_date)
                    // console.log(years);

                    const result = years?.reduce((acc, curr) => {
                        const year = curr.split('-')[0];
                        if (!acc.includes(year)) {
                            acc.push(year);
                        }
                        return acc;
                    }, []).sort()
                    setYearList(result)
                    // console.log("result", result);

                })
                .catch(() => {
                    console.log("error on geting retirements on project");
                })


            axios.get(`https://office.hempcarbonstandard.org/projectdetail/retirement-doc-on-project?id=${projectId}`)
                .then(d => {
                    setDocsBaseOnProject(d.data)
                    // console.log("retirementdocs", d.data);
                })
                .catch(() => {
                    console.log("error on geting retirements on project");
                })


            axios
                .get(
                    `https://office.hempcarbonstandard.org/projectdetail/project-detail?id=${projectId}`
                    // `https://office.hempcarbonstandard.org/projectdetail/all-project-website/?id=`
                )
                .then((d) => {
                    setApiData(d.data[0]);
                    // console.log("apiData", d.data[0]);
                    let dd = d.data[0]?.project_documents

                    dd.map(d => {
                        setDocsBaseOnProject(docsBaseOnProject => [...docsBaseOnProject, d])
                    })

                })
                .catch(() => {
                    console.log("error fetching data.");
                });


            axios
                .get(
                    "https://office.hempcarbonstandard.org/projectdetail/project-fields-website/"
                )
                .then((d) => {
                    setProjectFields(d.data[0]);
                })
                .catch(() => {
                    console.log("error fetching data.");
                });


        }
    }, [projectId])

    const getString = (id) => {
        let abc = "0"
        let length = id?.toString()?.length
        for (let a = 1; a < 4 - parseInt(length); a++) {
            abc = abc + "0"
        }
        return abc + id
    }


    return (
        <>
            <Helmet>
                <title>Registry - Trusted Carbon</title>
                <meta
                    name="description"
                    content="The Hemp Carbon Standard produces high integrity Carbon Removal Credits derived from a science based quantification methodology."
                />
                <meta
                    name="keywords"
                    content="Carbon Removal Credits, Buy Carbon Removal credits, Carbon Removal Credits USA, Buy Carbon Removal credits USA, Carbon Sequestration Credits, Buy Carbon Sequestration credits, 
          Carbon Removal Credits Europe, Buy Carbon Removal Credits, Carbon Removal Credits Canada, Buy Carbon Removal Credits Canada, 
          Carbon Removal Credits California, Carbon Removal Credits For Sale, Carbon Removal Credits Ukraine, Buy Carbon Removal Credits Ukraine."
                />
            </Helmet>

            <div>
                <p className='font-semibold w-[96%] text-[56px] ml-[2%] mt-12'>
                    {apiData?.farm}
                </p>

                <div className='border shadow-md w-[96%] md:w-[96%] mt-12 ml-[2%]'>
                    <div className='flex flex-col w-[96%] md:w-[48%] ml-[2%] py-8'>

                        <div className='flex items-center border-b-2 py-2'>
                            <p className='flex pr-2 w-1/4 m-0  '>ID:</p>
                            <p className='flex w-3/4 m-0  justify-start'>
                                {apiData?.id?.toString()?.length > "4" ? apiData?.id : getString(apiData?.id)}
                            </p>
                        </div>
                        <div className='flex items-center border-b-2 py-2'>
                            <p className='flex pr-2 w-1/4 m-0  '>NAME:</p>
                            <p className='flex w-3/4 m-0  justify-start'>
                                {apiData?.farm}
                            </p>
                        </div>

                        <div className='flex border-b-2 py-2'>
                            <p className='flex pr-2 w-1/4 m-0 '>METHOD:</p>
                            <p className='flex w-3/4 m-0 justify-start'>
                                {apiData?.method}
                            </p>
                        </div>

                        <div className='flex border-b-2 py-2'>
                            <p className='flex pr-2 w-1/4 m-0 '>COUNTRY:</p>
                            <p className='flex w-3/4 m-0 justify-start'>{apiData?.country}</p>
                        </div>

                    </div>
                </div>



                <div className='flex flex-col md:flex-row w-[96%] ml-[2%] mt-12'>
                    <div className="mt-4 h-[700px] border w-full md:w-[50%] px-4 overflow-auto">
                        <p className='font-semibold mt-[20px] text-[28px]'> Project Details</p>
                        <p className="mt-4">
                            <span className="font-semibold">Farm</span> - {apiData?.farm}
                        </p>
                        <p className="pt-2">
                            <span className="font-semibold">Category</span> -{" "}
                            {projectFields?.category}
                        </p>
                        <p className="pt-2">
                            <span className="font-semibold"> Standard - </span>{" "}
                            {projectFields?.standard}{" "}
                        </p>
                        <p className="pt-2">
                            <span className="font-semibold">Project Type - </span>{" "}
                            {projectFields?.project_type}
                        </p>
                        <p className="pt-2">
                            <span className="font-semibold">Status - </span>{" "}
                            {apiData?.status?.map((d) => (
                                <span key={d.id}>{d.value}, </span>
                            ))}
                        </p>
                        <p className="pt-2">
                            <span className="font-semibold">Validator - </span>{" "}
                            {projectFields?.validator}{" "}
                        </p>
                        <p className="pt-2">
                            <span className="font-semibold">Location -</span>{" "}
                            {apiData?.state + ", " + apiData?.country}
                        </p>
                        <p className="pt-2">
                            <span className="font-semibold">
                                {" "}
                                Project Duration (Months) -{" "}
                            </span>{" "}
                            {apiData?.project_duration}
                        </p>
                        <p className="pt-2">
                            <span className="font-semibold">Carbon Units Available - </span>
                            {" "}{apiData?.estimated_carbon_credit}

                        </p>
                        <p className="pt-2">
                            <span className="font-semibold"> Expected Validation Date -</span>{" "}
                            {apiData?.expected_validation_date}
                        </p>
                        <p className="pt-2">
                            <span className="font-semibold"> Validation Completed - </span>{" "}
                            {apiData?.validation_completed_date}
                        </p>
                        <p className="pt-2 pb-8 font-semibold"> Field Shape Files - </p>
                        <div className="grid grid-cols-3 md:grid-cols-4 gap-x-4">
                            {apiData?.filenames.map((d) => (
                                <div
                                    key={d.id}
                                    className="flex flex-col  items-center mb-8 bg-[#f5f5f5] rounded-md p-2"
                                >
                                    <img
                                        src={d.img}
                                        alt="Carbon Removal Credits, Buy Carbon Removal Credits"
                                        className="rounded-md"
                                    />
                                    <p>{d.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>


                    <div className=' mt-4 md:w-[50%] h-[700px]'>
                        <p className='font-semibold h-[40px] w-[96%] ml-[2%] md:w-[90%] md:ml-[5%] text-[28px]'> Project Documents</p>
                        <div className='border mt-[20px] w-[96%] ml-[2%] md:w-[90%] md:ml-[5%] flex flex-col h-[340px]'>
                            <p className='h-[40px] pl-4 bg-[#f5f5f5] flex items-center'>Name</p>

                            <div className='flex overflow-auto w-full'>
                                <div className='mt-4 w-full'>
                                    {docsBaseOnProject?.map((d) => (
                                        <div key={d.doc} className='mt-2 flex w-full'>
                                            <div className='w-3/4 flex items-center'>
                                                <p className='w-full font-semibold pl-4 break-words'                                                >
                                                    {d?.doc?.slice(43)}</p>
                                            </div>
                                            <div className='w-1/4 flex items-center justify-center' >
                                                <a key={d.doc} href={d?.doc} target="_blank" rel="noopener noreferrer" className=''>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="#1890ff"
                                                        className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>


                        </div>




                        <p className='font-semibold  mt-[20px] h-[40px]w-[96%] ml-[2%] md:w-[90%] md:ml-[5%] text-[28px]'>Issuance details</p>
                        <div className='h-[220px] mt-[20px] border w-[96%] ml-[2%] md:w-[90%] md:ml-[5%] overflow-auto'>
                            <div className='flex items-center bg-[#f5f5f5] h-[40px]  justify-around'>
                                <p>VINTAGE</p>
                                <p>TOTAL ISSUED</p>
                                <p>CREDIT TYPE</p>
                            </div>
                            <div className=' flex flex-col mt-2 justify-around'>
                                {yearList?.map(d => (
                                    <div key={d} className='flex mt-1  items-center  justify-around'>
                                        <p>{d}</p>
                                        <p>
                                            {(retirementBaseOnProject.filter(e => (
                                                e.retirement_date.split("-")[0] === d)
                                            ).map(f => f.total_issued_in_this_vintage).reduce((a, b) => parseInt(a) + parseInt(b)))}
                                        </p>

                                        {(retirementBaseOnProject.filter(e => (
                                            e.retirement_date.split("-")[0] === d)
                                        )[0].credit_type)}

                                        {/* <p>{retirementBaseOnProject.map(e => (
                                        JSON.stringify(e.id) === JSON.stringify("26"))
                                    )
                                    }</p> */}

                                        {/* {console.log(retirementBaseOnProject.filter(e => (
                                        e.retirement_date.split("-")[0] === d)
                                    ).map(f => f.total_issued_in_this_vintage).reduce((a, b) => parseInt(a) + parseInt(b)))} */}


                                        {/* <p> {(retirementBaseOnProject.filter(e => (
                                        e.retirement_date.split("-")[0] === d)
                                    )[0].id)}
                                    </p> */}
                                        {/* <p> {retirementBaseOnProject?.map(e => (
                                        e.retirement_date.split("-")[0] === d))[0].id

                                        // const year = curr.split('-')[0];
                                        // )
                                    }</p> */}

                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-12 pb-14'>
                    <p className='w-[96%] ml-[2%] font-semibold text-[28px]'>Retirement Detail</p>
                    <div className='overflow-auto mt-[20px] pt-4 pb-8 w-[96%] ml-[2%]'>
                        <table className='w-full'>
                            <thead className='h-[60px]'>
                                <tr className='w-full bg-[#f5f5f5]'>
                                    <th className=''><p> RETIREMENT DATE</p></th>
                                    <th className=''><p>BENEFICIARY NAME</p></th>
                                    <th className=''><p>RETIRED CREDITS</p></th>
                                    <th className=''><p>RETIREMENT PURPOSE</p></th>
                                    <th className=''><p>COUNTRY OF CONSUMPTION</p></th>
                                </tr>
                            </thead>
                            <tbody className='mt-20 border-0 w-full'>
                                {retirementBaseOnProject?.map(d => (
                                    <tr className='h-[60px] text-center' key={d.id}>
                                        <td className=''>{d.retirement_date}</td>
                                        <td className=''>{d.beneficiary}</td>
                                        <td className=''>{d.total_issued_in_this_vintage}</td>
                                        <td className=''>{d.retirement_purpose}</td>
                                        <td className=''>{d.country_of_consumption}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </>
    )
}
