// import { useRef } from "react";
import Header from "./header"
import Footer from "./footer"
import MyRoutes from "../myRoutes"


export default function Layout() {
    // const projectRef = useRef(null);
    return (
        <>
            <Header />
            <MyRoutes />
            <Footer />
        </>
    )
}
