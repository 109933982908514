import * as types from "./actionTypes.js";
import axios from "axios";



export const landingPage_projects = () => {
  return (dispatch) => {
    dispatch({
      type: types.PROJECTS,
      payload: {
        apiData_landingPage: null,
        // projectFields_landingPage: true,
      },
    });
    axios.get(
      "https://office.hempcarbonstandard.org/projectdetail/project-website/"
    )
      .then((d) => {
        dispatch({
          type: types.PROJECTS,
          payload: {
            apiData_landingPage: d.data,
          },
        });
      })
      .catch(() => {
        console.log("error fetching data.");
      })
  }
}

export const landingPage_projectDetails = () => {
  return (dispatch) => {

    dispatch({
      type: types.PROJECT_FIELDS,
      payload: {
        // apiData_landingPage: null,
        projectFields_landingPage: null,
      },
    });

    axios.get(
      "https://office.hempcarbonstandard.org/projectdetail/project-fields-website/"
    )
      .then((d) => {
        dispatch({
          type: types.PROJECT_FIELDS,
          payload: {
            projectFields_landingPage: d.data[0],
          },
        });
      })
      .catch(() => {
        console.log("error fetching data.");
      });
  };
}


